body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.ghost {
  position: absolute;
  width: 60px;
  height: 60px;
  transition: transform 0.1s;
  opacity: 0.2;
}


@font-face {
  font-family: 'Ghost';
  src: url('ghost.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



.logo{
  width: 500px;
  margin-top: -160px;
}

.tokenName{
  max-width: 360px;
  font-size: 60px;
  font-family: 'Ghost';
  display: flex;
  justify-content: center;
  height: 80px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.but{
    width: 424px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Ghost';
    font-size: 40px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(26, 136, 4);
    margin-top: 40px;
}

.env-but{
  width: 424px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.twitterLink{
  width: 202px;
  height: 100px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ghost';
  font-size: 30px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.telegramLink{
  width: 202px;
  height: 100px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ghost';
  font-size: 30px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.tokenCa{
  width: 524px;
  height: 55px;
  background-color: rgb(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: 'Ghost';
  font-size: 20px;
  margin-top: 40px;
}

.abo{
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  left: 20px;
}


.abol{
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.aa{
  font-size: 12px;
  color: white;
  text-align: center;
  letter-spacing: 1px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 100;
  width: 524px;
  margin-top: 5px;
}

.env-bloco{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}